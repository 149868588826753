/* eslint-disable import/prefer-default-export, react/prop-types */
import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { createTheme } from "./src/theme";
import { SnackbarProvider } from "notistack";
import { Provider as ReduxProvider } from "react-redux";
import store from "./src/store";
import { RTL } from "components/layout/RTL";
import { SettingsConsumer, SettingsProvider } from "contexts/settings-context";

function TopLayout(props) {
  return (
    <ReduxProvider store={store}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SettingsProvider>
          <SettingsConsumer>
            {({ settings }) => (
              <ThemeProvider
                theme={createTheme({
                  direction: settings.direction,
                  responsiveFontSizes: settings.responsiveFontSizes,
                  theme: settings.theme,
                  lang: settings.lang,
                })}
              >
                <SnackbarProvider maxSnack={3}>
                  <RTL direction={settings.direction}>
                    <CssBaseline />
                    {props.children}
                  </RTL>
                </SnackbarProvider>
              </ThemeProvider>
            )}
          </SettingsConsumer>
        </SettingsProvider>
      </LocalizationProvider>
    </ReduxProvider>
  );
}

export const wrapRootElement = ({ element }) => {
  return <TopLayout>{element}</TopLayout>;
};
